/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Typography, Box, Container, TextField } from "@mui/material";
import QRCode from "qrcode.react";
import logo from "../assets/logo.png";
import { io } from "socket.io-client";
import { API_URL, SOCKET_URL } from "../config/constants";

const RoundTimer = () => {
  const [roundTime, setRoundTime] = useState(300); // 5 minutes in seconds
  const [intervalTime, setIntervalTime] = useState(60); // 1 minute in seconds
  const [currentRound, setCurrentRound] = useState(1);
  const [totalRounds, setTotalRounds] = useState(3);
  const [remainingTime, setRemainingTime] = useState(roundTime);
  const [isRunning, setIsRunning] = useState(false);
  const [isInterval, setIsInterval] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const socketRef = useRef(null); // Reference to the Socket.IO client

  const formatTime = (seconds) => {
    const h = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const s = String(seconds % 60).padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  const handleSync = useCallback(() => {
    if (socketRef.current) {
      console.log("Sending sync event to server...", {
        time: remainingTime,
        isRunning,
        currentRound,
        isInterval,
      });
      socketRef.current.emit("sync", {
        time: remainingTime,
        isRunning,
        currentRound,
        isInterval,
      });
    }
  }, [remainingTime, isRunning, currentRound, isInterval]);

  const initializeSocket = (id) => {
    if (socketRef.current) {
      socketRef.current.disconnect(); // Disconnect any existing socket connection
    }

    socketRef.current = io(SOCKET_URL, { query: { sessionId: id } });

    socketRef.current.on("connect", () => {
      console.log("Socket.IO connection established with ID:", id);
      handleSync(); // Sync immediately upon connection
    });

    socketRef.current.on("message", (message) => {
      console.log("Received message from server:", message);
      // Handle messages as needed
    });

    socketRef.current.on("sync", (data) => {
      console.log("Sync data received:", data);
      // Ensure all values are numbers
      setRemainingTime(Number(data.time) || roundTime);
      setIsRunning(Boolean(data.isRunning));
      setCurrentRound(Number(data.currentRound) || currentRound);
      setIsInterval(Boolean(data.isInterval));
    });

    socketRef.current.on("disconnect", () => {
      console.log("Socket.IO connection closed");
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket.IO error:", error);
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const existingSessionId = urlParams.get("session_id");

    if (existingSessionId) {
      console.log("Using existing session ID:", existingSessionId);
      setSessionId(existingSessionId);
      initializeSocket(existingSessionId);
    } else {
      console.log("Generating a new session ID...");
      fetch(`${API_URL}/generate_session`)
        .then((response) => response.json())
        .then((data) => {
          const newSessionId = data.session_id;
          console.log("Generated new session ID:", newSessionId);
          setSessionId(newSessionId);
          initializeSocket(newSessionId);
          window.history.replaceState(
            null,
            null,
            `?session_id=${newSessionId}`
          );
        })
        .catch((error) => console.error("Error generating session:", error));
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect(); // Clean up on component unmount
      }
    };
  }, [handleSync, initializeSocket]);

  const handleRoundEnd = () => {
    if (isInterval) {
      if (currentRound < totalRounds) {
        startNextRound();
      } else {
        setIsRunning(false); // All rounds finished
      }
    } else {
      startInterval();
    }
  };

  useEffect(() => {
    let timer;
    if (isRunning && remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
        // handleSync();
      }, 1000);
    } else if (remainingTime === 0) {
      handleRoundEnd();
    }

    return () => clearInterval(timer);
  }, [isRunning, remainingTime, handleSync, handleRoundEnd]);

  const startNextRound = () => {
    setIsInterval(false);
    setCurrentRound((prevRound) => prevRound + 1);
    setRemainingTime(roundTime);
    setIsRunning(true);
  };

  const startInterval = () => {
    setIsInterval(true);
    setRemainingTime(intervalTime);
    setIsRunning(true);
  };

  const startTimer = () => {
    setIsRunning(true);
    socketRef.current?.emit("start");
  };

  const stopTimer = () => {
    setIsRunning(false);
    socketRef.current?.emit("stop");
  };

  const resetTimer = () => {
    setIsRunning(false);
    setRemainingTime(roundTime);
    setCurrentRound(1);
    setIsInterval(false);
    socketRef.current?.emit("reset");
  };

  const handleRoundTimeChange = (event) => {
    const [minutes, seconds] = event.target.value.split(":").map(Number);
    const totalSeconds = minutes * 60 + seconds;
    setRoundTime(totalSeconds);
    setRemainingTime(totalSeconds);
  };

  const handleIntervalTimeChange = (event) => {
    const [minutes, seconds] = event.target.value.split(":").map(Number);
    const totalSeconds = minutes * 60 + seconds;
    setIntervalTime(totalSeconds);
  };

  const handleTotalRoundsChange = (event) => {
    setTotalRounds(Number(event.target.value));
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#121212",
      }}
    >
      <img
        src={logo}
        alt="Company Logo"
        style={{ width: "300px", marginBottom: "40px" }}
      />
      <TextField
        label="Round (mm:ss)"
        type="time"
        step="1"
        defaultValue="00:05:00"
        onChange={handleRoundTimeChange}
        sx={{ marginBottom: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 1,
        }}
      />
      <TextField
        label="Interval (mm:ss)"
        type="time"
        step="1"
        defaultValue="00:01:00"
        onChange={handleIntervalTimeChange}
        sx={{ marginBottom: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 1,
        }}
      />
      <TextField
        label="Total Rounds"
        type="number"
        value={totalRounds}
        onChange={handleTotalRoundsChange}
        sx={{ marginBottom: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: 1,
        }}
      />
      <Box
        sx={{
          backgroundColor: "#1e1e1e",
          borderRadius: "16px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          {isInterval ? "Interval" : `Round ${currentRound}`}
        </Typography>
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
          {formatTime(remainingTime)}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={startTimer}
          sx={{ m: 1 }}
        >
          Start
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={stopTimer}
          sx={{ m: 1 }}
        >
          Stop
        </Button>
        <Button variant="contained" onClick={resetTimer} sx={{ m: 1 }}>
          Reset
        </Button>
      </Box>
      {sessionId && (
        <Box sx={{ mt: 4 }}>
          <QRCode
            value={`http://127.0.0.1:3000/?session_id=${sessionId}`}
            size={120}
          />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Scan this QR Code to sync with your phone.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default RoundTimer;
